import React from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import Reveal from "../motion/Reveal";

const ProductCard = ({ data }) => {
  return (
    <Reveal height className={"shadow-md hover:scale-105 duration-150"}>
      <Link
        to={"/products/" + data?._id}
        className=""
      >
      <div className=" brand_item-box text-center pb-3 h-full shadow-md">

        <div className="brand_img-box  bg-white ">
          <img src={CDN_BASE_URL + data?.img} className="w-full  p-3" alt="" />
        </div>
        <div className="brand_detail-box ">
          <h6 className="mt-2 text-[14px] sm:text-[16px] px-4">{data?.name}</h6>
        </div>
      </div>
      </Link>
    </Reveal>
  );
};

export default ProductCard;
