import React from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import RevealX from "../components/motion/RevealX";
import { BsDiamondFill } from "react-icons/bs";
import Reveal from "../components/motion/Reveal";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const ServicePage = () => {
  const [t,i18n] = useTranslation('global')
  return (
    <div className="">
     <Helmet>
        <title>آوام پردازان افلاک | سرویس ها</title>
        <meta name="og:title" content={"آوام پردازان افلاک | سرویس ها"} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div>
        <img
          className="w-full lg:h-[500px] object-bottom object-cover"
          src="/banner2.jpg"
          alt=""
        />
      </div>
      <div dir={i18n.language === 'en' ? 'rtl' : 'ltr'} className="container">
        {/* main sec */}
        <div className="lg:mt-[80px] mt-[30px]">
          <h1 className="custom_heading text-4xl text-center ">{t('services')}</h1>
          <Reveal>
            <p className="text-center mt-2">
              {t('services-text')}
            </p>
          </Reveal>
        </div>
        {/* first sec */}
        <div className=" py-4 bg-white lg:my-[80px] my-[20px]  grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-20">
          <div className="  col-span-1 order-2 lg:order-1 ">
            <RevealX head>
              <div className="flex items-center gap-1 justify-end mb-3">
                <h3 className="custom_heading text-[1.75rem] text-end ">
                  {" "}
                  {t('services-painting')}
                </h3>

                <span><BsDiamondFill size={28} color="#071777" /></span>
              </div>
            </RevealX>
            <RevealX head>
              <div className="text-end">
              {t('paint-desc')}.
              </div>
            </RevealX>
            <RevealX>
              <span className="flex justify-end my-3">
                <Link
                  to={"/service/service-paint"}
                  className="button-more w-fit bg-[#071777] px-8 py-2  text-white     border  border-[#071777] duration-100"
                >
                  {t('learn-more')}
                </Link>
              </span>
            </RevealX>
          </div>
          <span className=" order-1 lg:order-2">
            <Reveal>
              {" "}
              <img
                className="rounded shadow-md w-full"
                src="/paint.jpg"
                alt=""
              />
            </Reveal>
          </span>
        </div>
        {/* secend sec */}
        <div className=" py-4 bg-white lg:my-[80px] my-[20px]  grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-20">
          <div className="  col-span-1 order-2 lg:order-2 ">
            <RevealX>
              <div className="flex items-center gap-1 justify-end mb-3">
                <h3 className="custom_heading text-[1.75rem] text-end ">
                  {" "}
                  {t('logo-marketing')}
                </h3>

                <span><BsDiamondFill size={28} color="#071777" /></span>
              </div>
            </RevealX>
            <RevealX>
              <div className="text-end">
                {t('logo-desc')}.
              </div>
            </RevealX>
            <span className="flex justify-end my-4">
              <RevealX head>
                <Link
                  to={"/service/service-logo"}
                  className="button-more w-fit bg-[#071777] px-8 py-2  text-white     border  border-[#071777] duration-100"
                >
                  {t('learn-more')}
                </Link>
              </RevealX>
            </span>
          </div>
          <span className=" order-1 lg:order-1 h-[250px] lg:h-full">
            {" "}
            
              <Reveal >
                <img className="rounded shadow-md w-full  " src="/logoimg.jpeg" alt="" />
              </Reveal>
            
            
          </span>
        </div>
        {/* third sec */}
        <div className=" py-4 bg-white lg:my-[80px] my-[20px]  grid grid-cols-1 lg:grid-cols-2  gap-3 lg:gap-20">
          <div className="  col-span-1 order-2 lg:order-1 ">
            <RevealX head>
              <div className="flex items-center gap-1 justify-end mb-3">
                <h3 className="custom_heading text-[1.75rem] text-end ">
                  {" "}
                  {t('repair-polimer-material')}
                </h3>

                <span><BsDiamondFill size={28} color="#071777" /></span>
              </div>
            </RevealX>
            <RevealX head>
              <div className="text-end">
                {t('polimer-material-desc')}.
              </div>
            </RevealX>
            <RevealX>
              <span className="flex justify-end my-3">
                <Link
                  to={"/service/service-repair"}
                  className="button-more w-fit bg-[#071777] px-8 py-2  text-white     border  border-[#071777] duration-100"
                >
                  {t('learn-more')}
                </Link>
              </span>
            </RevealX>
          </div>
          <span className=" order-1 lg:order-2">
            <Reveal>
              {" "}
              <img
                className="rounded shadow-md w-full"
                src="/img2.jpg"
                alt=""
              />
            </Reveal>
          </span>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicePage;
