import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Filter from "../components/Filter";
import { useFormik } from "formik";
import Products from "../components/Products";
import Footer from "../components/Footer";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { BsDiamondFill } from "react-icons/bs";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import { CDN_BASE_URL } from "../config";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const ProjectsPage = () => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    setLoading(true);
    const res = await httpReauest("GET", "/project", {}, {});
    setData(res.data.data);
    setLoading(false);
  }
  const [t, i18n] = useTranslation("global");
  return (
    <div className="  ">
      <Helmet>
        <title>آوام پردازان افلاک | پروژه ها</title>
        <meta name="og:title" content={"آوام پردازان افلاک | پروژه ها"} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div
        dir="rtl"
        className="grid  lg:grid-cols-4 gap-8 w-full py-8 px-[10px] lg:px-10 xl:px-12 bg-white text-right"
      >
        {/* <div className="lg:col-span-1">
          <Filter formik={formik} />
        </div> */}
        <div className="lg:col-span-4">
          <span className="text-center text-[14px] lg:text-[17px]">
            <h2
              dir={i18n.language === "en" ? "rtl" : "ltr"}
              className="font-bold text-[28px] md:text-[40px]  2xl:text-[40px] flex items-center gap-2 justify-center mb-3"
            >
              {t("projects")}
              <BsDiamondFill size={22} color="#071777" />
            </h2>
            <p>{t("services-text")}</p>
          </span>
          <div className="mt-5 grid md:grid-cols-3 sm:grid-cols-2 lg:grid-cols-4 gap-3">
            {loading ? (
              <Loading />
            ) : (
              data?.map((item) => (
                <Link
                  to={"/projects/" + item?._id}
                  className="overflow-hidden rounded-lg shadow relative"
                >
                  <img
                    src={CDN_BASE_URL + item.img}
                    className="w-full h-[230px] sm:h-[290px] object-cover"
                    alt={item.name}
                  />
                  <span className="absolute text-[20px] bottom-0 right-0 text-center text-white pb-3 pt-4 px-3 bg-gradient-to-t from-[#071777] to-[#071777]/5 w-full">
                    {item?.name}
                  </span>
                </Link>
              ))
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectsPage;
