import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Footer from "../components/Footer";
import ScrollToTop from "../utils/ScrooltoTop";
import { Helmet } from "react-helmet";

const Contactpage = () => {
  return (
    <div className="hero_area">
     <Helmet>
        <title>آوام پردازان افلاک | ارتباط با ما</title>
        <meta name="og:title" content={"آوام پردازان افلاک | ارتباط با ما"} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <ContactUs/>
      {/* <InfoSection /> */}
      <Footer/>

    </div>
  );
};

export default Contactpage;
