import React from "react";
import { useTranslation } from "react-i18next";
import { BsDiamondFill } from "react-icons/bs";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Reveal from "../components/motion/Reveal";
import RevealX from "../components/motion/RevealX";
import ScrollToTop from "../utils/ScrooltoTop";
import { Helmet } from "react-helmet";

const LogoServicePage = () => {
  const [t,i18n] = useTranslation('global')
  return (
    
    <div>
     <Helmet>
        <title>{t("logo-marking")}</title>
        <meta name="og:title" content={t("logo-marking")} />
        <meta name="description" content={t("logo-text")} />
        <meta name="og:description" content={t("logo-text")} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div>
        <img
          className="w-full lg:h-[500px]  object-cover"
          src="/bannerlogo.jpeg"
          alt=""
        />
      </div>
      <div dir={i18n.language === 'en' ? 'rtl' : 'ltr'} className="container">
        <h1 className="text-4xl custom_heading text-center my-5">
          {t('logo-marking')}
        </h1>
        <RevealX>
          <p className="text-end mb-3">
            {t('logo-text')}.
          </p>
        </RevealX>
        <Reveal>
          <p className="text-end">
            {t('logo-text-2')} .
          </p>
        </Reveal>
        <div className="my-5 text-end">
          <Reveal>
            <h2 className=" custom_heading text-3xl mb-3">{t('projects')} </h2>
          </Reveal>
          <ul className="text-[15px]">
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t('logo-project')}</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t('logo-project-1')}</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t('logo-project-2')}-</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p> {t('logo-project-3')}-</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p> {t('logo-project-4')} </p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
          </ul>
        </div>
        <p className="text-end mb-5">
          {t('logo-project-5')}.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default LogoServicePage;
