import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { BsDiamondFill } from "react-icons/bs";
import Reveal from "../components/motion/Reveal";
import RevealX from "../components/motion/RevealX";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
const ServicePaintPage = () => {
  const [t, i18n] = useTranslation("global");
  return (
    <div>
      <Helmet>
        <title>{t("painting-h")}</title>
        <meta name="og:title" content={t("painting-h")} />
        <meta name="description" content={t("painting-desc")} />
        <meta name="og:description" content={t("painting-desc")} />
      </Helmet>
      <Header />
      <ScrollToTop />
      <div>
        <img
          className="w-full lg:h-[500px]  object-cover"
          src="/paint2.jpg"
          alt=""
        />
      </div>
      <div dir={i18n.language === "en" ? "rtl" : "ltr"} className="container">
        <h1 className="text-4xl custom_heading text-center my-5">
          {t("painting-h")}
        </h1>
        <RevealX>
          <p className="text-end mb-3">{t("painting-desc")}.</p>
        </RevealX>
        <Reveal>
          <p className="text-end">{t("painting-desc-1")}.</p>
        </Reveal>
        <div className="my-5 text-end">
          <Reveal>
            <h2 className=" custom_heading text-3xl mb-3">{t("projects")} </h2>
          </Reveal>
          <ul className="text-[15px]">
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t("paint-project")}</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t("paint-project-1")}</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t("paint-project-2")}</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
            <RevealX>
              <li className="flex justify-end items-center gap-1 my-1">
                <p>{t("paint-project-3")}.</p>
                <span>
                  <BsDiamondFill color="#071777" />
                </span>
              </li>
            </RevealX>
          </ul>
        </div>
        <div className="my-5 text-end">
          <Reveal>
            <h2 className="custom_heading text-3xl mb-3">{t("paint-cabin")}</h2>
          </Reveal>
          <RevealX>
            <p className="mb-3">{t("paint-cabin-desc")}.</p>
          </RevealX>
          <RevealX>
            <p className="mb-3">{t("paint-cabin-desc-1")}. </p>
          </RevealX>
          <Reveal>
            <p>{t("paint-cabin-desc-2")}.</p>
          </Reveal>
          <Reveal>
            <p className="text-center my-5">{t("quality")}...</p>
          </Reveal>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicePaintPage;
