import React, { useEffect, useRef } from "react";

// Import Swiper React components
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";

// import required modules
import { Autoplay, Pagination, EffectFade } from "swiper/modules";
import { Link } from "react-router-dom";
import Reveal from "./motion/Reveal";
import RevealX from "./motion/RevealX";
import { useTranslation } from "react-i18next";
const SliderVideo = () => {
  const ref = useRef();
  const ref2 = useRef();
  const [t,i18n] = useTranslation("global")
  useEffect(() => {
    ref.current.play();
    ref2.current.play();
  }, [i18n]);

  return (
    <div className="">
      <Swiper
        style={{ direction: "ltr" }}
        effect="fade"
        autoplay={{
          delay: 6000,
        }}
        modules={[Autoplay, EffectFade]}
        className="w-full"
      >
        <SwiperSlide>
          <div  className="relative bg-[#fff]">
            <video
              src="/video/3.mp4"
              ref={ref}
              muted
              loop
              playsinline
              className="w-full object-cover h-[95vh] bg-fixed"
            ></video>
            <span className="absolute left-0 top-0 w-full h-full bg-black/40"></span>

            <div
              dir={i18n.language === 'en' ? 'ltr' : 'rtl'}
              className="absolute top-[40%] md:top-[45%] translate-y-[-50%] right-[0] lg:right-[0%]  w-full mx-auto z-10  text-start text-white"
            >
              <RevealX delay={0.5}>
                <div className=" grid px-[20px] lg:px-[100px] md:grid-cols-3 ">
                  <span >
                    <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] ">
                      {t("avam-pardazan-aflak")}
                    </h2>
                    <p>
                      {" "}
                      {t("slider-text")}
                    </p>
                    <Link to={"/products"} className=" slider-btn1 mt-4">
                      {t("see-products")}
                    </Link>
                    <Link to={"/service"} className="inline-block px-[46px] lg:m-3 text-center py-[11px] bg-[#071777] duration-75 border-[1px] border-[#071777] hover:bg-transparent hover:text-[#071777] mt-3">
                      {t("our-services")}
                    </Link>
                  </span>
                </div>
              </RevealX>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="relative bg-[#000]">
            <video
              src="/video/2.mp4"
              autoPlay
              ref={ref2}
              muted
              loop
              playsinline
              className="w-full object-cover h-[95vh] bg-fixed"
            ></video>
            <span className="absolute left-0 top-0 w-full h-full bg-black/40"></span>

            <div
              dir={i18n.language === 'en' ? 'ltr' : 'rtl'}
              className="absolute top-[40%] md:top-[45%] translate-y-[-50%] right-[0] lg:right-[0%] w-full z-10  text-start text-white"
            >
              <RevealX delay={0.5}>
                <div className=" grid px-[20px] lg:px-[100px] md:grid-cols-3 ">
                  <span>
                    <h2 className="font-bold text-[28px] md:text-[40px]  2xl:text-[50px] ">
                    {t("avam-pardazan-aflak")}
                    </h2>
                    <p>
                      {" "}
                      {t("slider-text")}
                    </p>
                    <Link to={"/products"} className=" slider-btn1 mt-4">
                    {t("see-products")}
                    </Link>
                    <Link to={"/service"} className=" inline-block px-[46px] lg:m-3 text-center py-[11px] bg-[#071777] duration-75 border-[1px] border-[#071777] hover:bg-transparent hover:text-[#071777] mt-3">
                      {t("our-services")}
                    </Link>
                  </span>
                </div>
              </RevealX>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SliderVideo;
