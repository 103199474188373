import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import SliderVideo from "../components/SliderVideo";
import Footer from "../components/Footer";
import BestProducts from "../components/BestProducts";
import NewProducts from "../components/NewProducts";
import ServiceSection from "../components/ServiceSection";
import ScrollToTop from "../utils/ScrooltoTop";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import Reveal from "../components/motion/Reveal";

const Homepage = () => {
  const [t, i18n] = useTranslation("global");

  const images = [
    "/projects/1 (1).jpg",
    "/projects/1 (2).jpg",
    "/projects/1 (3).jpg",
    "/projects/1 (4).jpg",
    "/projects/1 (5).jpg",
    "/projects/1 (7).jpg",
    "/projects/1 (8).jpg",
    "/projects/1 (9).jpg",
    "/projects/1 (10).jpg",
  ];

  return (
    <div className="hero_area">
      <Header />
      <ScrollToTop />
      <SliderVideo />

      <div dir={i18n.language === "en" ? "rtl" : "ltr"} className="bg">
        <AboutSection />
        <ServiceSection />

        {/* <BestProducts /> */}
        <NewProducts />

        <div className="layout_padding2">
          <div className="container pb-2">
            <div className="brand_heading">
              <Reveal head>
                <h3 className="custom_heading text-[1.75rem] text-center">
                  {t("portfilo")}
                </h3>
              </Reveal>
            </div>
          </div>
          <div className="px-[10px] sm:px-10 lg:px-20 xl:px-36 ">
            <Swiper
              dir="ltr"
              breakpoints={{
                0: { slidesPerView: 1, spaceBetween: 10 },
                520: { slidesPerView: 2, spaceBetween: 25 },
                786: { slidesPerView: 3, spaceBetween: 25 },
                1048: { slidesPerView: 3, spaceBetween: 25 },
              }}
              modules={[Pagination, Autoplay]}
              autoplay={{ delay: 5000 }}
              pagination
              loop
              spaceBetween={25}
              className="mt-3"
            >
              {images.map((item) => (
                <SwiperSlide>
                  <img
                    src={item}
                    className="w-full rounded p-3 border "
                    alt={t("equipment")}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <WhySection />
      </div>

      <TestimonialSection />
      <ContactUs />
      {/* <InfoSection /> */}

      <Footer />
    </div>
  );
};

export default Homepage;
